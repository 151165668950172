<template>
  <div>
    <a-layout>
      <div class="content-header">
        <a-affix :offset-top="0" style="width: 100%;">
          <a-row type="flex" justify="space-between">
            <a-col span="12">
              <common-page-btn @category="$refs.category._show()"></common-page-btn>
            </a-col>
            <a-col span="12">
              <a-row type="flex" align="middle" justify="end" :gutter="3">
                <a-col span="6">
                  <a-date-picker placeholder="请选择日期" @change="onChange" type="date" valueFormat="YYYY-MM-DD" />
                </a-col>
                <a-col span="6">
                  <a-select v-model="param.status" style="width:100%" @change="e => {
                    getHonorList();
                  }
                    " placeholder="根据状态筛选">
                    <a-select-option value="">所有</a-select-option>
                    <a-select-option value="待审核">待审核</a-select-option>
                    <a-select-option value="审核通过">审核通过</a-select-option>
                    <a-select-option value="审核驳回">审核驳回</a-select-option>
                  </a-select>
                </a-col>
                <a-col span="6">
                  <a-select v-model="param.organId" style="width:100%" @change="e => {
                    getHonorList();
                  }
                    " placeholder="根据单位筛选">
                    <a-select-option value="">所有单位</a-select-option>
                    <a-select-option :value="organ.organId" v-for="(organ, index) in organList" :key="index">{{ organ.name
                    }}</a-select-option>
                  </a-select>
                </a-col>
                <a-col span="6">
                  <select-depart v-model="param.depId" @input="e => {
                    param.depId = e;
                    getHonorList();
                  }"></select-depart>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-affix>
      </div>
    </a-layout>

    <a-table :columns="columns" :defaultExpandAllRows="true" :scroll="{ x: '100%' }" :pagination="false" :indentSize="25"
             :style="{ background: '#FFF' }" size="small" row-key="id" border ref="list" :loading="loading"
             :data-source="data.records">
      <!-- 名字 -->
      <template slot-scope="row" slot="staffName">
        <a-button-group shape="circle" size="small">
          <a @click="utils.openImage(row.picture)">{{ row.staffName }}</a>
        </a-button-group>
      </template>
      <!-- 状态-->
      <template slot-scope="row" slot="status">
        <a-icon v-if="row.status == '待审核'" type="question-circle" />
        <a-icon v-if="row.status == '审核驳回'" style="color:red" type="close-circle" />
        <a-icon v-if="row.status == '审核通过'" style="color:#090" type="check-circle" />
      </template>
      <!--操作-->
      <template slot-scope="row" slot="action">
        <a-button-group shape="circle" size="small">
          <!-- <a-tooltip title="删除">
            <a-button type="link" icon="delete" @click="del(row.goodsId)"></a-button>
          </a-tooltip> -->
          <a-tooltip v-if="row.status == '待审核'" title="审批">
            <a-button type="link" icon="check" @click="e => { visible = true; id = row.id; }"></a-button>
          </a-tooltip>
        </a-button-group>
      </template>
    </a-table>
    <a-row>
      <a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
        <a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext" @showSizeChange="setLimit"
                      :current="data.current" />
      </a-col>
    </a-row>
    <a-modal title="审批" cancelText="取消审批" okText="确认审批" :visible="visible" :confirm-loading="confirmLoading"
             @ok="handleOk" @cancel="handleCancel">
      <a-form-model>
        <a-row :gutter="15">
          <a-col span="8">
            <a-form-model-item label="请选择是否通过审批" label-position="top">
              <a-switch v-model="isExamine" />
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item v-if="!isExamine" label="不通过原因" label-position="top">
              <a-input v-model="cause" placeholder="请输入不通过原因"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
    <category ref="category"></category>
  </div>
</template>

<script>
import apiUtil from '@/common/apiUtil';
import utils from '@/common/utils';
import selectDepart from '@/components/common/select-depart.vue';
import category from './category.vue';
export default {
  components: { selectDepart, category },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      data: {},
      records: [],
      loading: false,
      organList: apiUtil.getOrganList(),
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      pageSize: 10,
      total: '',
      isExamine: true,
      cause: '',
      id: '',
      param: {
        current: 1,
        limit: 20,
        keyword: '',
        createDate: '',
        status: '',
        depId: '',
      },
      columns: [
        { title: '姓名', scopedSlots: { customRender: 'staffName' }, width: 80, ellipsis: true, align: 'center' },
        { title: '荣誉名称', dataIndex: 'honorName', width: 80, ellipsis: true, align: 'center' },
        { title: '获得时间', dataIndex: 'dateEarned', align: 'center', width: 80, ellipsis: true },
        { title: '描述', dataIndex: 'honorDescription', align: 'center', width: 100, ellipsis: true },
        { title: '状态', scopedSlots: { customRender: 'status' }, align: 'center', width: 100 },
        { title: '上传时间', dataIndex: 'createDate', align: 'center', width: 100 },
        { title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 100 }
      ],
      formRule: {
        type: [{ required: true, message: '请选择类型' }],
        goodsId: [{ required: true, message: '请选择物品' }],
        number: [{ required: true, message: '请输入数量' }]
      }
    };
  },
  mounted() {
    this.getHonorList();
  },
  methods: {
    ...apiUtil,
    onChange(e) {
      this.param.createDate = e;
      this.getHonorList();
    },
    // 获取数据
    getHonorList() {
      this.loading = true;
      this.http
        .get('/platform/myHonor/rankRecordList', this.param)
        .then(ret => {
          this.data = ret.data;
          this.records = ret.data.records;
          this.total = data.total;
          this.param.current = data.current;
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
        });
    },
    // 确认审批
    handleOk() {
      let status = '';
      if (this.isExamine) {
        status = "审核通过";
      } else {
        status = "审核驳回";
      }
      this.http
        .post(`/platform/myHonor/approvalRankRecord`, { id: this.id, status, remark: this.cause })
        .then(ret => {
          this.utils.success('审批成功');
          this.visible = false;
          this.getHonorList();
        })
        .catch(err => {
          this.visible = false;
          this.getHonorList();
        });
    },
    // 取消审批
    handleCancel(e) {
      this.visible = false;
    },
    // 筛选条件变化重新获取数据
    doRefresh() {
      this.param.current = 1;
      this.getHonorList();
    },

    // 筛选单位
    filterOrgan(e) {
      this.param.organId = e;
    },
    //设置每页的显示条数
    setLimit(current, limit) {
      this.param.page = 1;
      this.param.limit = limit;
      this.getHonorList();
    },
    //页码切换
    getNext(page) {
      this.param.page = page;
      this.getHonorList();
    }
  }
};
</script>

<style></style>
