<template>
  <a-modal v-model="show" title="荣誉分级设置" :width="600" :footer="null" @cancel="() => {
    $emit('success');
    show = false;
  }
    " centered destroyOnClose>
    <a-table :scroll="{ x: '100%',y:'300px'}" :style="{ background: '#FFF' }" size="small" :columns="keys" row-key="categoryId"
             ref="list" :data-source="category" :loading="loading" :bordered="false" :pagination="false">
      <!--名称-->
      <template slot-scope="text, row, index" slot="rankName">
        <a-input v-model="row.rankName" v-if="row.edit" @pressEnter="confirmRow(row, index)"></a-input>
        <span v-else>{{ row.rankName }}</span>
      </template>
      <!-- 最小值 -->
      <template slot-scope="text, row, index" slot="minimum">
        <a-input-number v-model="row.minimum" :min="minNum" v-if="row.edit"
                        @pressEnter="confirmRow(row, index)"></a-input-number>
        <span v-else>{{ row.minimum }}</span>
      </template>
      <!-- 最大值 -->
      <template slot-scope="text, row, index" slot="maximum">
        <a-input-number :max="maxNum" v-model="row.maximum" :min="minNum + 1" v-if="row.edit"
                        @pressEnter="confirmRow(row, index)"></a-input-number>
        <span v-else>{{ row.maximum }}</span>
      </template>
      <!--操作-->
      <template slot-scope="text, row, index" slot="action">
        <a-button-group shape="circle" size="small">
          <a-tooltip title="编辑" v-if="!row.edit">
            <a-button icon="edit" @click="editRow(row, index)"></a-button>
          </a-tooltip>
          <a-tooltip title="保存" v-else>
            <a-button icon="check" @click="confirmRow(row, index)"></a-button>
          </a-tooltip>
          <a-tooltip title="取消" v-if="row.edit"><a-button icon="close" @click="delRow(row, index)"></a-button></a-tooltip>
          <a-tooltip title="删除" v-else><a-button icon="delete" @click="delRow(row, index)"></a-button></a-tooltip>
        </a-button-group>
      </template>
      <template slot="footer">
        <div class="footer">
          <a-button type="primary" :disabled="category[category.length - 1].maximum == 100000000" @click="addRow" size="small"
                    icon="plus">添加新荣誉等级</a-button>
        </div>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
export default {
  data() {
    return {
      show: false,
      loading: false,
      organId: apiUtil.getOrganId(),
      category: [],
      editname: [],
      minNum: 0,
      maxNum: 100000000,
      keys: [
        { title: '荣誉名称', scopedSlots: { customRender: 'rankName' }, width: 100, ellipsis: true },
        { title: '最小值', scopedSlots: { customRender: 'minimum' }, width: 100, ellipsis: true },
        { title: '最大值', scopedSlots: { customRender: 'maximum' }, width: 100, ellipsis: true },
        { title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 200 }
      ]
    };
  },
  methods: {
    ...apiUtil,
    getList() {
      this.loading = true;
      if (this.organId == 0) {
        this.organId = apiUtil.getDefaultOrganId();
      }
      this.http
        .get(`/platform/myHonor/getStaffRankList`)
        .then(ret => {
          console.log(ret);
          ret.data.forEach(item => (item.edit = false));
          this.category = ret.data;
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
        });
    },
    addRow() {
      this.minNum = this.category[this.category.length - 1].maximum + 1;
      const item = {
        id: '',
        rankName: '',
        maximum: '',
        minimum: this.category[this.category.length - 1].maximum + 1,
        edit: true
      };
      this.category.push(item);
    },
    delRow(row, index) {
      if (!row.id) {
        this.category.splice(index, 1);
      }
      if (row.edit) {
        row.edit = false;
        row.name = this.editname;
        return;
      }
      utils.confirm('您确认要删除此荣誉分级吗?').then(() => {
        utils.showSpin();
        request.get('/platform/myHonor/deleteStaffRankList', { id: row.id }).then(ret => {
          this.getList();
        });
      });
    },
    editRow(row, index) {
      row.edit = true;
      console.log(index);
      if (index < this.category.length) {
        this.minNum = this.category[index - 1].maximum + 1;
        this.maxNum = this.category[index + 1].minimum - 1;
      } else {
        this.minNum = 0;
        this.maxNum = 100000000;
      }

      this.editname = row.name;
    },
    confirmRow(row, index) {
      this.minNum = 0;
      this.maxNum = 100000000;
      utils.showSpin();
      // console.log(row);
      request.post('/platform/myHonor/staffOrUpdateRankSave', row).then(ret => {
        row.edit = false;
        this.getList();
      });
    },
    _show() {
      this.show = true;
      this.getList();
    },
    _close() {
      console.log(1);
      this.show = false;
    }
  }
};
</script>
<style>
.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>
